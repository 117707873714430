import axios from "axios";

export const sendInquiry = async ({ values, callback, errorCallback }) => {
	try {
		let response = await axios.post(
			process.env.GATSBY_INTEGROMAT_SEND_INQUIRY_URL,
			{
				...values,
				program: values.program.value,
			}
		);

		callback();
		return response;
	} catch (error) {
		errorCallback();
	}
};
